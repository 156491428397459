<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  async created() {
    const autoLoggedIn = await this.$store.dispatch('autoLogin');
    if (!autoLoggedIn) {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  background: #F0F2F5;
}
</style>
