import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state: {
        accessToken: localStorage.getItem('accessToken') || '',
        clubId: localStorage.getItem('clubId') || 0,
        role: localStorage.getItem('role') || '',
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
            localStorage.setItem('accessToken', token);
            localStorage.setItem('tokenIssuedAt', new Date().getTime()); // 토큰 발급 시간 저장
        },
        setRefreshToken(state, token) {
            document.cookie = `refreshToken=${token}; path=/;`; // 쿠키에 refreshToken 저장
        },
        setClubId(state, clubId) {
            state.clubId = clubId;
            localStorage.setItem('clubId', clubId);
        },
        setRole(state, role) {
            state.role = role;
            localStorage.setItem('role', role);
        },
        clearAuthData(state) {
            state.accessToken = '';
            state.clubId = 0;
            state.role = '';
            localStorage.removeItem('accessToken');
            localStorage.removeItem('clubId');
            localStorage.removeItem('role');
            document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // 쿠키에서 refreshToken 제거
        }
    },
    actions: {
        async setAuthData({ commit }, { accessToken, refreshToken, role, clubId }) {
            commit('setAccessToken', accessToken);
            commit('setRefreshToken', refreshToken); // refreshToken 쿠키에 저장
            commit('setClubId', clubId);
            commit('setRole', role);
        },
        async refreshToken({ commit }) {
            try {
                // 쿠키에서 refreshToken 가져오기
                const refreshToken = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('refreshToken='))
                    ?.split('=')[1];

                if (!refreshToken) {
                    throw new Error("Refresh token not found");
                }

                const response = await axios.post('https://api.donggurami.net/auth/refresh-token', {
                    refreshToken
                });

                const { accessToken, refreshToken: newRefreshToken } = response.data.data;

                // 새로운 accessToken과 refreshToken을 저장
                commit('setAccessToken', accessToken);
                commit('setRefreshToken', newRefreshToken);

                return accessToken;
            } catch (error) {
                commit('clearAuthData'); // refreshToken 실패 시 로그아웃 처리
                throw error;
            }
        },
        async autoLogin({ commit }) {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken) {
                try {
                    const now = new Date().getTime();
                    const tokenIssuedAt = localStorage.getItem('tokenIssuedAt');
                    if (tokenIssuedAt && now - tokenIssuedAt < 30 * 60 * 1000) {
                        return true;
                    } else {
                        const newAccessToken = await this.dispatch('refreshToken');
                        commit('setAccessToken', newAccessToken);
                        return true;
                    }
                } catch (error) {
                    commit('clearAuthData');
                    return false;
                }
            } else {
                commit('clearAuthData');
                return false;
            }
        },
        async logout({ commit }) {
            try {
                // 로그아웃 요청을 보내는 URL이 정확한지 확인
                await axios.post('https://api.donggurami.net/integration/logout', null, {
                    withCredentials: true // 쿠키를 자동으로 포함하여 전송
                });
            } catch (error) {
                console.error("로그아웃 시 에러 발생:", error);
            } finally {
                commit('clearAuthData');
            }
        }
    },
    getters: {
        isAuthenticated: state => !!state.accessToken,
        clubId: state => state.clubId,
        role: state => state.role
    }
});
